














import { Component, Vue, Prop } from "vue-property-decorator";
import AppLoading from "@/components/core/AppLoading.vue";

@Component({ components: { AppLoading } })
export default class AppButton extends Vue {
  @Prop({ type: String, default: "text" })
  readonly type!: "submit" | "button";

  @Prop({ required: true })
  readonly kind!: "primary" | "secondary" | "bad" | "neutral";

  @Prop({ type: Boolean, default: true })
  readonly block!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean;
}
