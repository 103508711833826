






import { Component, Vue } from "vue-property-decorator";

@Component
export default class TheVerticalCenter extends Vue {
  mounted() {
    document.querySelector("#app")?.classList.add("flex", "min-h-screen");
  }

  beforeDestroy() {
    document.querySelector("#app")?.classList.remove("flex", "min-h-screen");
  }
}
